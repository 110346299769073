.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 90% !important;
  outline: none;
}

.editcancelbtn {
  float: right;
  margin-top: -66px;
  margin-right: 66px;
  color: red;
  cursor: pointer;
}

.adderCollectS {
  margin-right: 1em;
}
.genderRadio {
  padding: 10px;

  /* padding-top:30px; */
  padding-top: 36px;
  padding-left: 0px;
}
.genderRadio .gispanicAlign {
  margin-left: 2em;
}
.genderRadio .gispanicAlignLabel {
  margin-right: 2em;
}

.datepickerstyleAdder input {
  padding: 10px;

  /* padding-top:30px; */
  padding-top: 36px;
}

.react-tel-input {
  margin-top: 23px !important;
}

#addPatient {
  padding: 0 2em;
  /* box-sizing: border-box; */
}

#addPatient div {
  /* padding: 2em; */
  box-sizing: border-box;
}

#addPatient .SplitPane .vertical {
  height: auto !important;
}
#addPatient .vertical {
  height: auto !important;
}
.patientTitle {
  padding: 30px 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  color: #263238;
  margin-bottom: 0em;

  font-size: 22px;
}
.patientInfo {
  color: black;
  font-size: 18px;
}
.addInput {
  display: flex;
  justify-content: start;
  align-items: center;
}

.primaryInsuranceRow {
  margin-top: 2em;
}
.primaryInsuranceRow > div {
  width: 48%;
}

.primaryInsuranceRow > div:nth-child(1) {
  margin-right: 4%;
}

.secondaryInsuranceRow {
  margin-top: 2em;
}

.secondaryInsuranceRow > div {
  width: 48%;
}

.secondaryInsuranceRow > div:nth-child(1) {
  margin-right: 4%;
}

.addPButtonAdj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addPButtonAdja {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addPButtonAdja > div:nth-child(1) {
  margin-right: 1em;
}

.heighteradj {
  margin-top: 2em !important;
}

.addPButtonAdja > div button {
  width: 200px;
}

.prioritizedList {
  display: flex;
  margin-bottom: 2em;
}
.prioritizedList > div:nth-child(1) {
  margin-right: 1em;
}
.prioritizedList label {
  font-size: 16px;
}

.fifthRowAdd {
  margin-top: 2em;
}

.sixthRowAdd {
  margin-top: 2em;
}

.sixthRowAdd > div {
  width: 48%;
}

.sixthRowAdd > div:nth-child(1) {
  margin-right: 4%;
}

.seventhRowAdd {
  margin-top: 2em;
}

.seventhRowAdd > div {
  width: 48%;
}

.seventhRowAdd > div:nth-child(1) {
  margin-right: 4%;
}

.fourthRowAdd {
  margin-top: 2em;
}

.fifthRowAdd > div {
  width: 48%;
}

.fifthRowAdd > div:nth-child(1) {
  margin-right: 4%;
}

.fourthRowAdd > div:nth-child(1) {
  width: 40%;
}

.fourthRowAdd > div:nth-child(1) {
  width: 40%;
}
.fourthRowAdd > div:nth-child(2) {
  width: 40%;
}
.fourthRowAdd > div:nth-child(3) {
  width: 20%;
}

.thirdRowAdd {
  margin-top: 2em;
}

.thirdRowAdd > div {
  width: 48%;
}

.thirdRowAdd > div:nth-child(1) {
  margin-right: 4%;
}

.secondRowAdd {
  margin-top: 2em;
}

.secondRowAdd > div {
  width: 48%;
}

.secondRowAdd > div:nth-child(1) {
  margin-right: 4%;
}

.firstRowAdd {
  margin-top: 2em;
}

.firstRowAdd > div:nth-child(1) {
  width: 35%;
}

.firstRowAdd > div:nth-child(2) {
  width: 24%;
  margin-left: 3%;
  margin-right: 3%;
}
.firstRowAdd > div:nth-child(3) {
  width: 35%;
}

.PatientInput::placeholder {
  color: #d3d7d8;
}
.dater {
  position: relative;
  width: 48%;
}
.calendar1 {
  position: absolute;
  width: 23px;
  top: 20px;
  right: 61px;
}
/* input[type="text"] {
  padding: 10px;
  width: 86%;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;

  font-size: 16px;
} */

.datepickerstyle input[type="text"] {
  padding-top: unset !important;
}

.MuiTextField-root {
  width: 90% !important;
}

#addPatient .MuiTextField-root {
  width: 100% !important;
}

.adjNa {
  margin-left: 1em;
}
.PatientInput {
  /* color: #D3D7D8; */
  padding: 10px;
  outline: none;
  padding-top: 30px;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;
  padding-right: 0px;
  font-size: 16px;
}
.PatientSelect {
  /* color: black; */
  padding: 10px;
  outline: none;
  /* padding-top: 30px; */

  padding-top: 24px;

  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;

  font-size: 16px;
}

.ethnicit {
  font-weight: 500;
  font-size: 15px;
  color: #00afb9;

  font-size: 18px;

  font-size: 16px;
}
.NextBtn {
  background: #f07167;
  border: none;
  outline: none;
  padding: 12px;
  width: 20%;
  color: white;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 40px;
  font-size: 15px;
  cursor: pointer;
}

.NextBtn:focus {
  border: 1px solid #f07167;
}

/* 2nd section */

.setFull {
  width: 100%;
}

.prioritized {
  color: #00afb9;
  margin: 2em 0;

  padding-bottom: 0px;
  font-size: 16px;

  color: #f07167;
}
.checkinsurance {
  margin-top: 25px;

  font-size: 16px;
}
/* 
 .checkinsurance input[type="checkbox"] + label:before {
    background: #fff !important;
    border: #00AFB9 1px solid !important;
  } */

input[type="checkbox" i] {
  background: #fff !important;
  border: #00afb9 1px solid !important;
}
.InfoTool {
  cursor: pointer;
  height: 20px;
  vertical-align: middle;
  /* margin-top: 14px; */
  margin-left: 6px;
}

.MuiIconButton-root {
  border: none !important;
}
.accordionOne > .MuiAccordionSummary-root,
.accordionTwo > .MuiAccordionSummary-root {
  padding: 0px 5px !important;
}
