table {
  border-spacing: 0;
  border: 1px solid #ededed;
}

table th {
  background-color: #00afb9 !important;
  color: #ffffff !important;
  font-weight: bolder;
}
.DatePicker__input
{
  text-align: left !important;
}
table th,
table td {
  margin: 0;
  padding: 0.8rem;
  border-bottom: 1px solid #ededed;
  border-right: 2px solid #ededed;
  position: relative;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}

.inputSearch {
  min-width: 79vw;
  padding: 10px;
  margin: 10px 0;
  font-size: 18px;
  border-radius: 5px;
  border: 2px solid #00afb9 !important;
  box-shadow: none;
}
.inputSearch:active {
  border: 2px solid #00afb9 !important;
}

.srchbtn {
  background: #f07167;
  border: none;
  width: 100px;
  padding: 10px;
  font-size: 15px;
  color: white;
  border-radius: 9px;
  cursor: pointer;
}
.srchbtn:focus {
  /* border: 1px solid #dd625a !important; */
  border: none !important;
}
.exportBtn {
  background: #f07167;
  border: none;
  padding: 10px;
  font-size: 15px;
  color: white;
  border-radius: 9px;
  cursor: pointer;
}
.exportBtn:focus {
  /* border: 1px solid #dd625a !important; */
  border: none !important;
}

.DatePicker__input {
  border: none !important;
  border-bottom: 1px solid #00afb9 !important;
  width: 230px;
  padding: 7px 10px !important;
  font-size: 15px !important;
  color: #00afb9 !important;
  margin-right: 15px;
  /* text-transform: capitalize !important; */
}
.DatePicker__input:focus-visible {
  /* border-bottom: none !important; */
  /* outline: 1px solid #00afb9 !important; */
  outline: none !important;
}

.DatePicker__input::placeholder {
  color: gray !important;
}
.filter_row {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-bottom: 10px;
}
.search_label {
  font-size: 16px !important;
  margin-bottom: 8px;
}
.search_filter {
  width: 230px;
  font-family: Nunito, sans-serif !important;
}

.MuiInputBase-input {
  font-size: 15px !important;
  color: #00afb9 !important;
  font-family: Nunito, sans-serif !important;
}
.MuiInputLabel-formControl {
  color: gray !important;
  padding: 10px 0 !important;
}
.searchRow {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 25px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .filter_row {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
}
