footer{
   
    color: dimgrey;
   font-size: 13px;
    padding:0.7em 0;
    text-align: center;

    width: 100%;
}
.Footermain
{
    display: flex;
    justify-content: center;
}
.textlogo
{
    width: 135px;
    margin-top: 5px;
}

.footerlogo
{
    width: 35px;
}
.footerP
{
    margin: 0px !important;
    float: left;
}

.textDiv
{
    margin-left: 10px;
}