.btnAdj>button:nth-child(1){
    margin-right: 1em;
}

.ethnicit22{
    font-weight: 500;
    font-size: 15px;
    color: #00AFB9;
    font-size: 18px;
    font-size: 16px;

    display: block;
    width: 103%;
}


.dailyUpdateForm{
    margin-top: 1em;
}

.dailerDates{
    margin-top:1em;
}

.infoer{
    color:grey;
}


.dailyContainer {
  padding: 1em 2em;
}

.dailyContainer h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  /* identical to box height */

  color: #263238;
  margin-bottom:0em;

  font-size:22px;
}

.dailer{
    display: flex;
    align-items: center;
}

.dailer img{
    margin-left: 1em;
    vertical-align: middle;
    cursor: pointer;
}

.dailyData{
    background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1em;
  margin-bottom: 1.5em;
}

.dailyData .date{
    display: flex;
   
    padding-bottom:1em;
}

.editicon{
    cursor: pointer;
}

.datepickerstyle input[type="text"] {
    padding-top: unset !important;
}

.dailyData .date>div:nth-child(1){
    
    color:  #00AFB9;
    font-size: 16px;
}


.dailyData .date>div:nth-child(2){
    
    color:#F07167;
    font-size: 16px;

}

.dailyData>div:nth-child(2)
{
    border-bottom: 1px solid #00AFB9;
}
.dailyData>div:nth-child(3)
{
    border-bottom: 1px solid #00AFB9;
}
.dailyDateTitle{
    display: flex;
    justify-content: space-between;
    padding:10px;
}

.dailyDateTitle>div{
    display: flex;
}

.dailyDateTitle>div:nth-child(1)>div,.dailyDateTitle>div:nth-child(2)>div{
    color:#F07167;
    font-weight: 500;
    font-size: 16px;
}

.dailyDateTitle>div:nth-child(2)>div:nth-child(1){
    color:#00AFB9;
    
}

.dailyDateTitle>div:nth-child(2)>div:nth-child(2){
    color:#F07167;
    font-weight: 500;
}

.dailyCount{
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    /* border-bottom: 1px solid #00AFB9; */
    padding-bottom: 1em;
}

.dailyCount >div>div:nth-child(1){
    color:#00AFB9;
    margin-bottom:0.7em;

    font-size: 16px;
}

.dailyCount >div>div:nth-child(2){
    font-size: 16px;
}

.dailyUpdateForm .dater{
    position: relative;
}

.dailyUpdateForm .calendar{
    position: absolute;
    width: 23px;
    top: 20px;
    right: -4px;
}

.dailyUpdateForm input[type="text"] {
    padding: 10px;
    width: 100%;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #d3d7d8;
  }
  
  .dailyUpdateForm input[type="date"] {
    padding: 10px;
    width: 100%;
    outline: none;
    padding-top: 30px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #d3d7d8;
  }



  .binaxForm .firstRow{
      display: flex;
  }

  .binaxForm .firstRow>div{
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.binaxForm .firstRow>div:nth-child(1){
    margin-right:1em;

}



.binaxForm .secondRow{
    display: flex;
}

.binaxForm .secondRow>div{
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}

.binaxForm .secondRow>div:nth-child(1){
  margin-right:1em;

}


.binaxForm .thirdRow{
    display: flex;
    margin-top: 1.5em;
    justify-content: space-between;
    align-items: center;
    margin-left: -10px;
    width: 102%;
}

.binaxForm .fourthRow{
    display: flex;
    width: 94%;
    margin-top: 1.5em;
    justify-content: space-between;
    align-items: center;
    
}
.dailyUpdateForm .fourthRow{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px !important;
    width: 48%;
}

.binaxForm .fourthRow>div{
    width: 51%;
  
  }

.binaxForm .thirdRow>div{
  width: 47%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}

.binaxForm .thirdRow>div:nth-child(1){
  margin-right:2em;

}

.customH1{
    font-weight: 500 !important;
font-size: 20px !important;
line-height: 43px !important;
/* identical to box height */




/* color: #00AFB9 !important; */
color: #F07167 !important;

margin-top:1em;
margin-bottom:1em !important;

}

.subimission{
    background: #F07167;
    border: none;
    outline: none;
    padding: 12px;
    width: 20%;
    color: white;
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 40px;
    cursor: pointer;
}

.btnAdj{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:2em 0;
}

.editHolder{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #00AFB9;
}