.backIcon {
  vertical-align: middle;
  margin-right: 10px;
  cursor: pointer;
}

.flexer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ResupplyContainer {
  padding: 1em 2em;
}

.ResupplyContainer .PatientSelect {
  padding: 10px;
  outline: none;
  padding-top: 30px;

  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;

  font-size: 16px;
}

.ResupplyContainer h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  /* identical to box height */

  color: #263238;
  font-size: 22px;
}

.searcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchAgain {
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  /* identical to box height */

  color: #f07167;
  border: 1px solid #f07167;
  background: #fff;
  border-radius: 15px;
  padding: 0em 1em;
  cursor: pointer;
}

.subimission:focus {
  border: 1px solid #f07167;
}

.ResupplyContainer input[type="text"] {
  padding: 10px;
  width: 100%;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;
}

.ResupplyContainer input[type="number"] {
  padding: 10px;
  width: 100%;
  outline: none;

  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;
}
.RequestForm .firstRow {
  display: flex;
}

.RequestForm .firstRow > div {
  width: 50%;
}

.RequestForm .firstRow > div:nth-child(1) {
  margin-right: 1em;
}

.RequestForm .secondRow > div {
  width: 100%;
}

.history {
  font-weight: 500 !important;
  font-size: 22px !important;
  line-height: 63px !important;
  /* identical to box height */

  color: #00afb9 !important;
}

.historyData {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1em 2em;
  margin-bottom: 1.5em;
}

.firstRowHistory {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.firstRowHistory > div:nth-child(1) {
  line-height: 34px;

  color: #00afb9;

  font-size: 16px;
}

.firstRowHistory > div:nth-child(2) {
  line-height: 37px;

  color: #f07167;

  font-size: 16px;
}

.firstRowHistory > div:nth-child(3) {
  display: flex;
  align-items: center;
}

.firstRowHistory > div:nth-child(3) > div:nth-child(1) {
  line-height: 34px;

  color: #00afb9;

  font-size: 16px;
}
.firstRowHistory > div:nth-child(3) > div:nth-child(2) {
  /* line-height: 34px; */
  color: #f07167;
  border: 1px solid #f07167;
  /* box-sizing: border-box; */
  border-radius: 92px;
  padding: 4px 18px;
  margin-left: 5px;

  font-size: 16px;
}

.secondRowHistory {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

.secondRowHistory > div:nth-child(1) {
  margin-right: 5px;
  color: #00afb9;
  line-height: 31px;

  font-size: 16px;
}
.secondRowHistory > div:nth-child(2) {
  line-height: 31px;
  /* identical to box height */

  color: #c1c1c1;
  word-break: break-word;

  font-size: 16px;
}

.btn {
  background: #f07167;
  border: none;
  outline: none;
  padding: 12px;
  width: 240px;
  color: white;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 40px;
  font-size: 15px;
  cursor: pointer;
}

.btnAdj2 {
  display: flex;
  justify-content: center;
}

.btnAdj2 > div:nth-child(1) {
  margin-right: 1em;
}
.resupplyHis {
  text-align: center;
}
