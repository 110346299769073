.loginAdj{
    height: 100vh;
    max-height: 100vh;
}

.loginBG
{
    background-image:url('../../Image/login-bg.png');
    background-size: cover;
    height: 89vh;
    background-position: 50% 50%;
    
}
.bviHeader
{
    width: 175px;
    float: left;
    margin-left: 30px;
    margin-top: 5px;
}
.logo
{
    height: 50px;
    margin: 12px;
    float: left;

}
.logo1
{
    width: 100px;
   
}
.Info
{
   cursor:pointer;
   width:20px;
   vertical-align: middle;
   margin-top: 14px;
    margin-left: 4px;
}
.inputDiv
{
    margin-top: 2em;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    width: 40%;
    padding: 10px;
    border-radius: 15px;
    margin-left: 2em;
}
.welcome
{
    
    
    margin-top: 4em;
    text-align: left;
    margin-left: 2em;
    
}
.textInput
{
    font-size: 16px;
    padding: 10px;
    outline: none;
    padding-top: 30px;
    width: 91%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #02afb9;
}

.welcome h3{
    font-weight: 100;
font-size: 38px;
line-height: 57px;

margin:0em;

color: #000000;
}

.welcome h4{
    color: #02afb9;
    font-weight: 500;
font-size: 35px;
line-height: 52px;
/* identical to box height */

letter-spacing: 0.03em;
margin:0em;

color: #00AFB9;
  

}
.subBtn
{
    background: #F07167;
    border: none;
    outline: none;
    padding: 12px;
    width: 30%;
    color: white;
    border-radius: 10px;
    margin-top: 55px;
    font-size: 15px;
    cursor: pointer;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.centerAdj{
    display: flex;
    justify-content: center;
}

/* OTP css */
.otpSec
{
    width: 100px; 
    margin-top: 40px;
}
.otpDiv{
    border-radius: 20px;
    margin-top: 3%;
    margin-left: 26%;
    width: 45%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
}

.otpDiv>div:nth-child(2){
    font-size:16px;
}
.textInput1
{
    font-size: 16px;
    padding: 10px;
    outline: none;
    padding-top: 30px;
    width: 60%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #D3D7D8;
}
.subBtn1{
    background: #F07167;
    border: none;
    outline: none;
    padding: 12px;
    width: 30%;
    color: white;
    border-radius: 10px;
    margin-top: 70px;
    margin-bottom: 40px;
    font-size: 15px;
    cursor: pointer;
}

.validError{
    margin-top:1em;
    color:red;
}


.RolerSelect
{
    /* color: black; */
    padding: 10px;
    outline: none;
    padding-top: 30px;
    width: 90%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #D3D7D8;
    text-align: center;
    font-size: 16px;
}

.RolerSelect option{
    text-align: center;
}
button:focus {
   border:1px solid black;
  }
