.collectContainer {
  padding: 1em 2em;
}

.collectContainer h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  color: #263238;
  margin-bottom: 0em;

  font-size: 22px;
}

.collectContainer .addInput{
  margin-top: 25px;
  margin-left: 23px;
  display: flex;
  justify-content: start;
}

.collectContainer .PatientSelect {
  outline: none;
  padding: 30px 10px 10px;
  border: none;
  border-bottom: 1px solid #d3d7d8;

  width:88%;  
}

.formHolder {
  display: flex;
}

.formHolder > div > div {
  width: 88%;
}

.formHolder > div {
  width: 50%;
}

.collectContainer input[type="text"] {
  padding: 10px;
  width: 85%;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;
  font-size: 16px;
}
@media only screen and (min-width: 1400px) and (max-width: 2000px) {
  .confCode
{
  width: 50% !important;
}
  .searchContainer input[type="text"]
  {
  width: 90% !important;

  }
.collectContainer input[type="text"] {
  width: 90% !important;
}
.Appointment_name
{
   width: 47% !important
}
}
.collectContainer input[type="date"] {
  padding: 10px;
  width: 100%;
  outline: none;
  padding-top: 30px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;
  font-size: 13px;
}
.collectinput input[type="text"] 
{
  width: 41.5% !important;
}
.DisplayIte input[type="text"] 
{
  width: 88% !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */

  color: #d3d7d8;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d3d7d8;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d3d7d8;
}

.btnFix {
  display: flex;
  justify-content: center;
}

.subBtn1 {
  background: #f07167;
  border: none;
  outline: none;
  padding: 12px;
  width: 20%;
  color: white;
  border-radius: 10px;
  margin-top: 55px;
  font-size: 15px;
}

.subBtn1:focus{
  border: 1px solid #f07167;
}

.eye {
  cursor: pointer;
  width: 32px;
}

.firstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.firstRow > div {
  display: flex;
  align-items: center;
}

.firstRow > div > div:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 31px;
  min-width: 92px;

  color: #00afb9;
  font-size: 16px;
}

.firstRow > div > div:nth-child(2) {
  line-height: 31px;

  color: black;

  font-size: 16px;
}

.secondRow {
  display: flex;
  margin-top: 1.5em;
  justify-content: space-between;
  align-items: center;
}
.secondRow > div {
  display: flex;
  align-items: center;
}
.secondRow .collectSample > div {
  font-weight: 500 !important;
  font-size: 21px !important;
  line-height: 37px !important;

  color: #f07167 !important ;
}

.secondRow > div > div:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 31px;

  color: #00afb9;
  min-width: 92px;
}

.secondRow > div > div:nth-child(2) {
  line-height: 31px;

  color: black;
}

.data {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1em 2em;
  margin-bottom: 1.5em;
}

.searcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchAgain {
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  /* identical to box height */
  outline: none;
  color: #f07167;
  border: 1px solid #f07167;
  background: #fff;
  border-radius: 15px;
  padding: 0em 1em;
  cursor: pointer;
}

.searchAgain:focus{
  border: 1px solid #f07167;
}

.dater {
  position: relative;
}

.calendarcollect {
  position: absolute;
  width: 23px;
  top: 20px;
  right: 64px;
}
.CollectSelect {
  color: #d3d7d8;
  padding: 10px;
  outline: none;
  padding-top: 30px;
  width: 105%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;
}
.specimenLabel {
  margin-top: 100px;
  margin-left: 25px;
  width: 50%;
  font-size: 15px;
}
.CollectionLabel {
  margin-top: 40px;
  margin-left: 25px;
  width: 50%;
  font-size: 15px;
}
.Lableimg {
  width: 21px;
}
.sampleSuccess {
  color: #0eda47;
  font-size: 18px;
  font-weight: 700;
}
.sampleSuccessDiv {
  text-align: center;
}
.viewer-toolbar-current-page-input {
  display: none !important;
}
.pdfclose {
  float: right;
  cursor: pointer;
  margin-top: -11px;
  font-size: 20px;
  font-weight: 700;
  color: #f07167;
  padding: 2px;
}
.closeicon {
  width: 30px;
}
.Pdfhead {
  padding: 1%;
  text-align: center;
  color: black;
  font-size: 16px;
}
.pdfbody {
  width: 90%;
  margin: 0 auto;
}
.collectinput
{
  margin-top: 20px;
}
.confCode
{
  width: 50% !important;
}
.confcodelist1
{
  margin-left: -8px;
}
.collectbtn{
  float: right;
  margin-top: -28px;
  margin-right: 66px;
  color: #F07167;
  cursor: pointer
}
.Collcetlists{
  width: 50%;
}
.checkAlign{
  margin-left: 95px;
}
.checkfirst
{
  margin-top: 20px;
  font-size: 16px
}
/* hide pdf unwanted */
/*  */
@media print {
  .rpv-body-printing .shedule {
      display: none;
  }
}
@media print {
  /* Hide all elements inside #root */
  .rpv-body-printing #root {
      display: none;
  }

  /* Show the prepared pages */
  .rpv-print-zone {
      display: block;
  }
}