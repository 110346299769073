.adjSearchMargin{
  margin-bottom: 1em;
}
.datepickerstyle1 input[type="text"]{
  padding-top:30px !important;
}

.patientsearchContainer {
  padding: 1em 2em;
}

.patientsearchContainer input[type="number"] {
  padding: 10px;
  width:85%;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;
  font-family: 'Nunito', sans-serif;
}
@media only screen and (min-width: 1400px) and (max-width: 2000px) {
  .patientsearchContainer input[type="text"]
  {
    width: 90% !important;
  }
  .patient_name
  {
     width: 47% !important
  }
  .patient_lname{
    width: 49%;
  }
  }
  .patient_name
  {
    margin-top: 20px;
     width: 49%
  }
  .patient_lname{
    margin-top: 20px;
    width: 49%;
    margin-left: 14px;
  }
.patientsearchContainer h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 45px;
  color: #263238;
  margin-bottom: 0em;

  font-size: 22px;
}

.PatientformHolder {
  display: flex;
  align-items: center;
}

.PatientformHolder > div > div {
  width: 100%;
}

.PatientformHolder > div {
  width: 50%;
}
.PatientformHolder .react-tel-input {
  margin-top: 30px !important;
}
.PatientformHolder label + .MuiInput-formControl {
  margin-top: 0px;
}
.patientsearchContainer input[type="text"] {
  padding: 10px;
  width: 85%;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;
  font-family:'Nunito', sans-serif;
  font-size: 16px;
}

.patientsearchContainer input[type="date"] {
  padding: 10px;
  width: 100%;
  outline: none;
  padding-top: 30px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;
  font-family:'Nunito', sans-serif;
}

.btnFix {
  display: flex;
  justify-content: center;
}

.subBtn1 {
  background: #f07167;
  border: none;
  outline: none;
  padding: 12px;
  width: 20%;
  color: white;
  border-radius: 10px;
  margin-top: 55px;
  font-size: 15px;
}

.eye {
  width: 32px;
}

.firstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.firstRow > div {
  display: flex;
  align-items: center;
}

.firstRow > div > div:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 31px;
  min-width: 92px;

  color: #00afb9;
}

.firstRow > div > div:nth-child(2) {
  line-height: 31px;

  color: black;
}

.secondRow {
  display: flex;
  margin-top: 1.5em;
  justify-content: space-between;
  align-items: center;
}
.secondRow > div {
  display: flex;
  align-items: center;
}
.secondRow .collectSample > div {
  font-weight: 500 !important;
  font-size: 21px !important;
  line-height: 37px !important;

  color: #f07167 !important ;
}

.secondRow > div > div:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 31px;

  color: #00afb9;
  min-width: 92px;
}

.secondRow > div > div:nth-child(2) {
  line-height: 31px;

  color: black;
}
.collectSample
{
  cursor: pointer;
}
.data {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1em 2em;
  margin-bottom: 1.5em;
}

.searcher{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searchAgain
{
    font-weight: 500;
font-size: 20px;
line-height: 45px;
/* identical to box height */


color: #F07167;
border:1px solid #F07167;
background: #fff;
border-radius: 15px;
padding: 0em 1em;
cursor: pointer;
}

.searchAgain:focus{
  border: 1px solid #f07167;
}


.dater{
    position: relative;
}

.calendar{
    position: absolute;
    width: 23px;
    top: 20px;
    right: 64px;
}
.DisplayIte
{
  display: flex;
}