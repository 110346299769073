
body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

}
.MuiTableCell-root
{
  font-family:'Nunito', sans-serif !important ;
}
code {
  font-family: 'Nunito', sans-serif;
}
button{
  outline: none !important;
}
.viewP{
  cursor: pointer;
  color: #00AFB9;
  font-size: 18px;
}
input {
  font-family: inherit !important;
}
button
{
  font-family: inherit !important;

}
/* datepicker */

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}
select{
  color:black !important
}
.SplitPane {
  min-height: 90vh !important;
  height:auto !important;
  position: unset !important;
}
input:disabled {
background: white !important;
}

.loaderAdj{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
 }


 ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */

  color: #d3d7d8;
  opacity: 1; /* Firefox */
font-family: 'Nunito', sans-serif !important;

}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d3d7d8;
  font-family: 'Nunito', sans-serif !important;

}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d3d7d8;
  font-family: 'Nunito', sans-serif !important;

}

 select{
   font-family: 'Nunito', sans-serif !important;
   font-size: 16px;
 }



 .MuiInputLabel-formControl{
color: #d3d7d8 !important;
font-weight: unset !important;
font-family: 'Nunito', sans-serif !important;
font-size:16px !important;
 }

 .MuiInput-underline:after{
   border-bottom:2px solid #00AFB9 !important;
 }


 .MuiSvgIcon-root {
  fill: #00AFB9 !important;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid  #d3d7d8 !important;
  pointer-events: none;
}

.MuiTypography-subtitle1 {
  font-size: 1.5rem !important;
  font-family: 'Nunito', sans-serif !important;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
}

.MuiCircularProgress-colorPrimary {
  color: #00AFB9 !important;
}

.showSuccessmsg{
  color: #0eda47;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
    margin-top: 1em;
}

button:focus {
  border:1px solid black;
 }

 .feedback {
	position: fixed;
	right: 0;
	bottom: 37%;
	height: 160px;
	width: 32px;
	color: #FFF;
	cursor: pointer;
 }

 .feedback:active {
	transform: translateY(0px) scale(0.98);
	filter: brightness(1);
}