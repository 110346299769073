.MuiListItemText-root {
  display: inline-block !important;
}

.searchContainer {
  padding: 1em 2em;
}
.rec .NextBtn {
  background: #f07167;
  border: none;
  outline: none;
  padding: 12px;
  width: 90%;
  color: white;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 40px;
  font-size: 15px;
  cursor: pointer;
  outline: none;
}
.recordAdjresend {
  margin-left: 0.8em;
  background: #f07167;
  border: none;
  outline: none;
  padding: 12px;
  /* width: 20%; */
  color: white;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 40px;
  font-size: 15px;
  cursor: pointer;
}

.flexing {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.datepickerstyle2 input[type="text"] {
  padding: unset !important;
  margin-top: 30px;
  padding-bottom: 10px !important;
}
.fa-phone {
  margin-left: 15px;
  font-size: 21px;
  color: #f07167;
}
.searchContainer {
  padding: 1em 2em;
}

.searchContainer h1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  color: #263238;
  margin-bottom: 0em;
}

.formHolder {
  display: flex;
}

.formHolder > div > div {
  width: 88%;
}

.formHolder > div {
  width: 50%;
}

.searchContainer input[type="text"] {
  padding: 10px;
  width: 85%;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;
}

.searchContainer input[type="date"] {
  padding: 10px;
  width: 100%;
  outline: none;
  padding-top: 30px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */

  color: #d3d7d8;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d3d7d8;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d3d7d8;
}

.btnFix {
  display: flex;
  justify-content: center;
}

.subBtn1 {
  background: #f07167;
  border: none;
  outline: none;
  padding: 12px;
  width: 20%;
  color: white;
  border-radius: 10px;
  margin-top: 55px;
  font-size: 15px;
}

.eye {
  cursor: pointer;
  width: 32px;
}

.firstRowrecord {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #d3d7d8;
}
.firstRowrecord > div {
  display: flex;
  align-items: center;
  width: 33.3%;
  max-width: 33.3%;
}

.firstRowrecord > div > div:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 31px;

  padding-right: 10px;
  color: #00afb9;

  font-size: 16px;

  /* min-width: 128px; */

  min-width: 58px;
}

.firstRowrecord > div > div:nth-child(2) {
  line-height: 31px;

  color: black;

  font-size: 16px;
}

.secondRowrecord {
  display: flex;
  margin-top: 2.5em;
  justify-content: space-between;
  align-items: center;
}
.secondRowrecord > div {
  display: flex;
  align-items: center;
  width: 33.3%;
  max-width: 33.3%;
}
.secondRowrecord .collectSample > div {
  font-weight: 500 !important;
  font-size: 21px !important;
  line-height: 37px !important;

  color: #f07167 !important ;
}

.secondRowrecord > div > div:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 31px;

  color: #00afb9;
  padding-right: 10px;
  font-size: 16px;

  /* min-width: 128px; */

  min-width: 58px;
}

.secondRowrecord > div > div:nth-child(2) {
  line-height: 31px;

  color: black;
  font-size: 16px;
}

.data {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1em 2em;
  margin-bottom: 1.5em;
}

.PCR_data{
  background: #ffffff;
  box-shadow: 0px 0px 20px #357a38;
  border-radius: 20px;
  border:1px solid #357a38;
  padding: 1em 2em;
  margin-bottom: 1.5em;
}

.PCRText{
  color:#357a38 !important;
}

.ctValueRecod{
  display: flex;
  margin-top: 2.5em;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  color: #00afb9;
}
/* .ctValueRecod > input[type="number"]{
  width: 235px !important; 
} */

  .ctValueText {
    min-width: 200px;
  margin-top: 5px;
  border: none !important;
  outline: none !important;
  border-bottom: 1px solid #d3d7d8 !important;
  }  

.searcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchAgain {
  font-weight: 200;
  font-size: 12px;
  line-height: 28px;
  outline: none;
  color: #f07167;
  border: 1px solid #f07167;
  background: #fff;
  border-radius: 15px;
  padding: 0em 1em;
  cursor: pointer;
}

.dater {
  position: relative;
}

.calendarcollect {
  position: absolute;
  width: 23px;
  top: 20px;
  right: 64px;
}
.CollectSelect {
  color: #d3d7d8;
  padding: 10px;
  outline: none;
  padding-top: 30px;
  width: 105%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d7d8;
}
.specimenLabel {
  margin-top: 100px;
  margin-left: 25px;
  width: 50%;
  font-size: 15px;
}
.CollectionLabel {
  margin-top: 40px;
  margin-left: 25px;
  width: 50%;
  font-size: 15px;
}

.sampleSuccess {
  color: #0eda47;
  font-size: 18px;
  font-weight: 700;
}
.sampleSuccessDiv {
  text-align: center;
}
.checkrecodrds {
  color: #f07167;
  display: flex;
}
/* .checkrecodrds .checkinsurance {
  margin-left: 60px;
} */
.MuiInputLabel-asterisk {
  display: none !important;
}

.resultUpdate {
  color: #f07167;
  text-align: center;
  margin-top: 2.5em;

  color: #0eda47;
}

.thirdRowrecord {
  display: flex;
  margin-top: 2.5em;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
}
.thirdRowrecord > div {
  display: flex;
  align-items: center;
  width: 33.3%;
  max-width: 33.3%;
}
.thirdRowrecord .collectSample > div {
  font-weight: 500 !important;
  font-size: 21px !important;
  line-height: 37px !important;

  color: #f07167 !important ;
}

.thirdRowrecord > div > div:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 31px;

  color: #00afb9;
  padding-right: 10px;
  font-size: 16px;

  /* min-width: 128px; */
  min-width: 58px;
}

.thirdRowrecord > div > div:nth-child(2) {
  line-height: 31px;

  color: black;
  font-size: 16px;
}

.labelimager {
  height: 18px;
  margin-top: -2px;
  margin-right: 7px;
}

.fourthRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;
}

.fourthRow > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.recordAdj {
  width: unset;
  margin-top: unset;
  margin-bottom: unset;
}

.checkAdj {
  padding: unset;
  padding-right: 0px;
  margin-top: unset;
  margin-bottom: unset;
}

.resBtn {
  margin-right: 2em;
}
.recordFormerAdj {
  margin-top: 1.5em;
}
.MuiListItemText-primary {
  /* color: red; */
  font-size: 12px !important;
}
