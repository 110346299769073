.MuiInputLabel-root {
  color: #263238 !important;
}

.container {
  padding: 1em 2em;
}

.tableForm {
  padding-top: 2em;
  padding-bottom: 2em;
}
.gridcontent {
  width: 65%;
}

@media only screen and (min-width: 769px) and (max-width: 1366px) {
  .gridcontent {
    width: 60% !important;
    overflow-x: scroll;
  }
}

.resnd {
  cursor: pointer;
  color: #f07167;
  font-size: 18px;
}

.modalBox {
  position: fixed; /* Stay in place */
  z-index: 1111111 !important; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 45%; /* Could be more or less, depending on screen size */
  border-radius: 20px;

  position: relative;
}

.closeIcn {
  position: absolute;
  right: 5px;
  width: 24px;
  cursor: pointer;
  margin-top: -15px;
}

.btnAdjHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 2em 0; */
}
.btnAdjHeader > button:nth-child(1) {
  margin-right: 1em;
  /* margin: 2em 0; */
}

.subBtn10 {
  background: #f07167;
  border: none;
  outline: none;
  padding: 12px;
  width: 20%;
  color: white;
  border-radius: 10px;
  margin-top: 24px;
  font-size: 15px;
  cursor: pointer;
}

.logoheader {
  display: flex;
}

.modal-content > div:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  height: 30px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 4px 4px #00000025;
  padding: 20px 10px;
  border-radius: 0px 0px 26px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.logoutImg {
  margin-right: 19px;
  margin-left: 20px;
  margin-top: -8px;
  cursor: pointer;
}

.floatRighter {
  display: flex;
}

.floatRighter span {
  color: #f07167;
  font-size: 18px;
}

.facilityName {
  color: #00afb9;
  font-size: 22px;
}
.linkBack {
  margin-right: 40px;
  color: red;
  cursor: pointer;
  margin-top: 40px;
}

.notifi {
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  color: #263238;
  margin-bottom: 0em;
  font-size: 22px;
}
.css-yj4kkt {
  margin-top: 5px !important;
  line-height: 16px !important;
  font-size: 13px !important;
}
.css-1u006va-Control {
  border-bottom: 1px solid #c4cdd6 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.css-1wy7sfl {
  padding: 3px !important;
}
.css-c3cekv {
  padding: 4px !important;
}

.innerselect .MuiFormControl-root {
  width: 100% !important;
}
.innerselect1 .MuiFormControl-root {
  width: 102% !important;
}
