.container{
    padding: 1em 2em;
}

header h1{
    font-size: 22px;
    font-weight: 500;
}
.modalBox{
  
 
    position: fixed; /* Stay in place */
    z-index: 1111111 !important; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  .modal-content{
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 45%; /* Could be more or less, depending on screen size */
    border-radius:20px;
  
    position: relative;
  }
  
  .closeIcn{
    position:absolute;
    right: 5px;
    width: 24px;
    cursor: pointer;
    margin-top: -15px;
  }
  
  .btnAdjHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 2em 0; */
  }
  .btnAdjHeader>button:nth-child(1) {
    margin-right:1em;
    /* margin: 2em 0; */
  }
  
  .subBtn10 {
    background: #f07167;
    border: none;
    outline: none;
    padding: 12px;
    width: 20%;
    color: white;
    border-radius: 10px;
    margin-top: 24px;
    font-size: 15px;
    cursor: pointer;
  }
  .closeIcnnew{
    position: absolute;
    right: 13px;
    top: 0px;
    background: #00AFB9;
    border: none;
    outline: none;
    padding: 12px;
    width: 130px;
    color: #fff;
    border-radius: 10px;
    margin-top: 15px;
    font-size: 12px;
    cursor: pointer;
    margin-right: 2em;
  }
  .backer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .NextBtn1{
    background: #00AFB9;
    border: none;
    outline: none;
    position: absolute;
    padding: 12px;
    width: 130px;
    color: #fff;
    border-radius: 10px;
    margin-top: 55px;
    font-size: 12px;
    cursor: pointer;
    margin-right: 2em;
  }
.paperModal {
    position: absolute;
    width: 60%;
    background-color: #fff;
    border-radius: 20px;
    /* overflow: scroll !important; */
    outline: none;
  }
  
  .logoheader{
    display: flex;
  }
  
  .modal-content>div:nth-child(1){
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.imageHolders{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .imageHolders img{
    cursor: pointer;
  }
  .imageHolders>div:nth-child(1){
    margin-right:0.7em;
  }
.loginform{
    padding-top: 2em;
    padding-bottom: 2em;
}
.NextBtn {
    background: #f07167;
    border: none;
    outline: none;
    padding: 12px;
    width: 20%;
    color: white;
    border-radius: 10px;
    margin-top: 50px;
    margin-bottom: 40px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
  }
  
.labelStyle{
    color: #02afb9;
}
.MuiFormControl-root
    {
    padding-top: 11px !important;
}

.textDate{
    font-size: 16px;
    padding: 10px;
    outline: none;
    padding-top: 30px;
    width: 91%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #02afb9;
    text-transform: uppercase;
}
.textInput
{
    font-size: 16px;
    padding: 10px;
    outline: none;
    width: 91%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #02afb9;
}
.btnDiv{
    padding-top: 2em;
    padding-bottom: 2em;
}
.centerAdj{
    display: flex;
    justify-content: center;
}
.subBtn{
    background: #F07167;
    border: none;
    outline: none;
    padding: 12px;
    width: 27%;
    color: white;
    border-radius: 10px;
    margin-top: 55px;
    font-size: 16px;
    cursor: pointer;
}

/* Mobile */
@media only screen and (max-width: 600px) {
    .inputDiv {
      width: 80% !important;
    }
    .loginBG
    {
        background-image:none !important;
        background-size: cover; 
    }
    .mobformat
{
    margin-left: 25% !important;
}
.footeerlogin
{
    margin-top: 25px !important;
}
.welcome h3 {
    font-size: 27px !important;
}
.welcome h4 {
    font-size: 30px !important;
}
  }
  /* Tab */
  
@media only screen and (min-width:601px) and (max-width:768px){

    .mobformat
    {
        margin-left: 25% !important;
    }
    .inputDiv{
        width: 50% !important
    }
  }